export default {
  USER_AVATAR: {
    path: 'users',
  },
  USER: {
    path: 'users',
  },
  PRODUCT_EVALUATE: {
    path: 'evaluate',
  },
  USER_COLLECTION_CODE: {
    path: 'users',
  },
  BUSINESS_LICENSE: {
    path: 'users',
  },
  AUTHENTICATION: {
    path: 'users',
  },
  REPORT: {
    path: 'report',
  },
  DEMAND: {
    path: 'require',
  },
  SERVICE: {
    path: 'ts',
  },
  SHOP: {
    path: 'users',
  },
  JOB: {
    path: 'pt',
  },
  SOURCE_CODE: {
    path: 'sc',
  },
  FEEDBACK: {
    path: 'mo',
  },
  DOCS: {
    path: 'td',
  },
  DESIGN: {
    path: 'ds',
  },
  ORDER: {
    path: 'order',
  }
}
